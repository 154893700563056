import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { keyframes } from '@emotion/react';

const shakeAnimation = keyframes`
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-4px); }
  75% { transform: translateX(4px); }
`;

const GridTile = ({
  row,
  col,
  value,
  onClick,
  isBlocked,
  isPermanent,
  isDuplicate
}) => {
  const [placementAnimating, setPlacementAnimating] = useState(false);
  const [errorAnimating, setErrorAnimating] = useState(false);
  const [wasInvalid, setWasInvalid] = useState(false);
  const [prevValue, setPrevValue] = useState(value);

  useEffect(() => {
    if (value !== prevValue) {
      setPlacementAnimating(true);
      const timer = setTimeout(() => setPlacementAnimating(false), 100);
      return () => clearTimeout(timer);
    }
    setPrevValue(value);
  }, [value]);

  useEffect(() => {
    if (isDuplicate && !wasInvalid) {
      setErrorAnimating(true);
      const timer = setTimeout(() => {
        setErrorAnimating(false);
        setWasInvalid(false);
      }, 500);
      return () => clearTimeout(timer);
    }
    setWasInvalid(isDuplicate);
  }, [isDuplicate]);

  return (
    <Button
      variant="outlined"
      onClick={onClick}
      disabled={isBlocked || isPermanent}
      sx={{
        minWidth: 0,
        width: '100%',
        height: '100%',
        fontSize: { xs: '0.875rem', sm: '1.5rem' },
        p: { xs: 0.5, sm: 1 },
        bgcolor: isDuplicate ? 'error.light' :
          isBlocked ? '#424242' :
          '#fafafa',
        color: '#000000',
        opacity: isPermanent ? 1 : 0.87,
        borderColor: isDuplicate ? 'error.main' :
          isBlocked ? '#424242' :
          isPermanent ? 'primary.main' : '#e0e0e0',
        borderWidth: isPermanent ? 2 : 1,
        transform: placementAnimating ? 'scale(1.1)' : 'scale(1)',
        animation: errorAnimating ? `${shakeAnimation} 0.5s ease` : 'none',
        transition: 'all 0.1s cubic-bezier(0.34, 1.56, 0.64, 1), background-color 0.3s ease',
        '&:hover': {
          bgcolor: !isBlocked && !isPermanent
            ? isDuplicate ? 'error.light' : '#eeeeee'
            : undefined,
          transform: !isBlocked && !isPermanent && !placementAnimating ? 'scale(1.05)' : undefined,
        },
        boxShadow: isPermanent ? '0 0 0 1px rgba(25, 118, 210, 0.2)' : 'none',
      }}
    >
      {value || ''}
    </Button>
  );
};

export default GridTile;