import React from 'react';
import { Box, Typography, Button, Modal, IconButton } from '@mui/material';
import { X as CloseIcon, ShareIcon, SendIcon } from 'lucide-react';

const ModalContent = ({ children }) => (
  <Box
    sx={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '90%',
      maxWidth: '400px',
      bgcolor: 'white',
      borderRadius: '8px',
      p: 4,
      outline: 'none',
      textAlign: 'center',
    }}
  >
    {children}
  </Box>
);

export const ShareModal = ({ open, onClose, onShare, grid, isComplete }) => {
  const getShareGrid = () => {
    return grid.map(row => 
      row.map(cell => {
        if (!cell) return '⬜';
        return cell;
      }).join('')
    ).join('\n');
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ModalContent>
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>

        <Typography variant="h5" sx={{ mb: 3, fontWeight: 'bold' }}>
          {isComplete ? "Puzzle Complete!" : "Share Progress"}
        </Typography>

        <Box
          sx={{
            mb: 3,
            p: 2,
            bgcolor: '#f5f5f5',
            borderRadius: 1,
            fontFamily: 'monospace',
            whiteSpace: 'pre',
          }}
        >
          {getShareGrid()}
        </Box>

        <Button
          fullWidth
          startIcon={<ShareIcon />}
          variant="contained"
          onClick={onShare}
          sx={{
            mb: 2,
            color: 'white',
            bgcolor: 'black',
            borderRadius: '20px',
          }}
        >
          Share
        </Button>
      </ModalContent>
    </Modal>
  );
};

export const ShareButton = ({ onClick }) => (
  <Button
    onClick={onClick}
    startIcon={<SendIcon />}
    sx={{
      mt: 2,
      color: "#000",
      textDecoration: "underline",
      "&:hover": {
        bgcolor: "transparent",
      },
    }}
  >
    Send to a friend
  </Button>
);