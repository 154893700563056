export const SEATTLE_WORDS = [
  // November 2024 - January 2025 (Original List)
  {
    date: "2024-11-15",
    theme:
      "This park was named after the Secretary of State who negotiated the Alaska Purchase, featuring a gorgeous lakefront and amphitheater",
    word: "SEWARD",
    characterCount: 6,
  },
  {
    date: "2024-11-16",
    theme:
      "These springtime blossoms draw thousands of visitors to the UW Quad each March and April",
    word: "CHERRY",
    characterCount: 6,
  },
  {
    date: "2024-11-17",
    theme:
      "This engineering marvel in Ballard allows boats to travel between different water levels",
    word: "LOCKS",
    characterCount: 5,
  },
  {
    date: "2024-11-18",
    theme:
      "This snow-capped peak is visible from Seattle on clear days, standing as Washington's third-highest mountain",
    word: "BAKER",
    characterCount: 5,
  },
  {
    date: "2024-11-19",
    theme:
      "This Alki Beach restaurant offers spectacular water views and renowned seafood dishes",
    word: "SALTYS",
    characterCount: 6,
  },
  {
    date: "2024-11-20",
    theme:
      "This stadium, previously known as CenturyLink Field, hosts NFL games and major concerts",
    word: "LUMEN",
    characterCount: 5,
  },
  {
    date: "2024-11-21",
    theme:
      "This waterfront neighborhood honors a Nisqually chief who was friendly with early settlers",
    word: "LESCHI",
    characterCount: 6,
  },
  {
    date: "2024-11-22",
    theme:
      "Home to Seattle's first public swimming pool, this Central District park remains a community hub",
    word: "PRATT",
    characterCount: 5,
  },
  {
    date: "2024-11-23",
    theme:
      "Seattle's oldest Japanese restaurant, serving sushi in the International District since 1904",
    word: "MANEKI",
    characterCount: 6,
  },
  {
    date: "2024-11-24",
    theme:
      "Look for the iconic red neon sign of this historic bakery near the waterfront",
    word: "FRANZ",
    characterCount: 5,
  },
  {
    date: "2024-11-25",
    theme:
      "A major downtown avenue named for a pioneering family who arrived in the 1850s",
    word: "BOREN",
    characterCount: 5,
  },
  {
    date: "2024-11-26",
    theme:
      "This retail giant began in Seattle's SODO district, famous for bulk shopping and $1.50 hot dogs",
    word: "COSTCO",
    characterCount: 6,
  },
  {
    date: "2024-11-27",
    theme:
      "Visit this city park's Japanese gardens for an unmatched view of the Cascades",
    word: "HILLMAN",
    characterCount: 7,
  },
  {
    date: "2024-11-28",
    theme:
      "This fine dining institution above Lake Union has hosted celebrities and special occasions since 1950",
    word: "CANLIS",
    characterCount: 6,
  },
  {
    date: "2024-11-29",
    theme:
      "A beloved local coffee chain that started in 1993 on Queen Anne Hill",
    word: "LADRO",
    characterCount: 5,
  },
  {
    date: "2024-11-30",
    theme: "Floating bridges connect Seattle to this Lake Washington island",
    word: "MERCER",
    characterCount: 6,
  },
  {
    date: "2024-12-01",
    theme:
      "This Capitol Hill restaurant puts an Asian twist on American fast food classics",
    word: "UMAMI",
    characterCount: 5,
  },
  {
    date: "2024-12-02",
    theme:
      "These flowers create stunning multicolored fields in the Skagit Valley each spring",
    word: "TULIP",
    characterCount: 5,
  },
  {
    date: "2024-12-03",
    theme:
      "When forecasters predict this weather event, Seattleites clear grocery store shelves",
    word: "FREEZE",
    characterCount: 6,
  },
  {
    date: "2024-12-04",
    theme:
     "This Seattle startup created an innovative video booth for capturing guest messages at weddings and events",
    word: "VOAST",
    characterCount: 5,
  },
  {
    date: "2024-12-05",
    theme: "This underground passageway replaced the Alaskan Way Viaduct",
    word: "TUNNEL",
    characterCount: 6,
  },
  {
    date: "2024-12-06",
    theme:
      "This music movement put Seattle on the cultural map in the early 1990s",
    word: "GRUNGE",
    characterCount: 6,
  },
  {
    date: "2024-12-07",
    theme:
      "This volcanic peak creates its own weather systems visible from Seattle",
    word: "BAKER",
    characterCount: 5,
  },
  {
    date: "2024-12-08",
    theme: "Seattle's WNBA team, four-time league champions",
    word: "STORM",
    characterCount: 5,
  },
  {
    date: "2024-12-09",
    theme:
      "These majestic birds can often be spotted fishing along the Puget Sound shoreline",
    word: "EAGLE",
    characterCount: 5,
  },
  {
    date: "2024-12-10",
    theme:
      "This Seattle Center museum celebrates popular culture and music history",
    word: "MOPOP",
    characterCount: 5,
  },
  {
    date: "2024-12-11",
    theme: "The weather condition Seattle is most famous for",
    word: "RAINY",
    characterCount: 5,
  },
  {
    date: "2024-12-12",
    theme:
      "This stunning Japanese garden in South Seattle was created by a pioneering Asian-American landscaper",
    word: "KUBOTA",
    characterCount: 6,
  },
  {
    date: "2024-12-13",
    theme:
      "This martial arts legend is buried in Lake View Cemetery on Capitol Hill",
    word: "BRUCE",
    characterCount: 5,
  },
  {
    date: "2024-12-14",
    theme:
      "Watch these iconic fish leap up the fish ladder at the Ballard Locks",
    word: "SALMON",
    characterCount: 6,
  },
  {
    date: "2024-12-15",
    theme:
      "These commercial fishing boats can be seen heading out from Fishermen's Terminal",
    word: "FISHER",
    characterCount: 6,
  },
  {
    date: "2024-12-16",
    theme:
      "These playful marine mammals can sometimes be spotted in Elliott Bay",
    word: "OTTER",
    characterCount: 5,
  },
  {
    date: "2024-12-17",
    theme:
      "This hill offers some of the best city views and was once Seattle's primary LGBTQ+ neighborhood",
    word: "QUEEN",
    characterCount: 5,
  },
  {
    date: "2024-12-18",
    theme: "These famous trees line the UW Quad and bloom each spring",
    word: "CHERRY",
    characterCount: 6,
  },
  {
    date: "2024-12-19",
    theme:
      "This performing arts venue near Seattle Center was named for a telecommunications pioneer",
    word: "MCCAW",
    characterCount: 5,
  },
  {
    date: "2024-12-20",
    theme:
      "This waterfront area houses hundreds of boats and offers stunning sunset views",
    word: "MARINA",
    characterCount: 6,
  },
  {
    date: "2024-12-21",
    theme:
      "This valley north of Seattle is famous for its tulip fields and eagle watching",
    word: "SKAGIT",
    characterCount: 6,
  },
  {
    date: "2024-12-22",
    theme:
      "These large public art pieces can be found throughout Pioneer Square and the International District",
    word: "MURAL",
    characterCount: 5,
  },
  {
    date: "2024-12-23",
    theme:
      "This street on Capitol Hill is home to many popular restaurants and bars",
    word: "OLIVE",
    characterCount: 5,
  },
  {
    date: "2024-12-24",
    theme:
      "This island, accessible only by ferry, is known for its artist community and local farms",
    word: "VASHON",
    characterCount: 6,
  },
  {
    date: "2024-12-25",
    theme: "This Mariners baseball legend has a statue outside T-Mobile Park",
    word: "EDGAR",
    characterCount: 5,
  },
  {
    date: "2024-12-26",
    theme:
      "This iconic restaurant near Pike Place Market is known for its coconut cream pies",
    word: "THEPI",
    characterCount: 5,
  },
  {
    date: "2024-12-27",
    theme:
      "This park offers one of Seattle's best viewpoints of downtown and Mount Rainier",
    word: "KRACKE",
    characterCount: 6,
  },
  {
    date: "2024-12-28",
    theme:
      "This historic downtown destination is famous for fish-throwing and local vendors",
    word: "MARKET",
    characterCount: 6,
  },
  {
    date: "2024-12-29",
    theme: "Watch planes take off and land from the observation deck at Seatac",
    word: "FLIGHT",
    characterCount: 6,
  },
  {
    date: "2024-12-30",
    theme: "This South Lake Union museum preserves and shares local history",
    word: "MOHAI",
    characterCount: 5,
  },
  {
    date: "2024-12-31",
    theme:
      "This popular medical TV show was set in Seattle but filmed elsewhere",
    word: "GREYS",
    characterCount: 5,
  },
  {
    date: "2025-01-01",
    theme: "This major downtown street is home to the original Nordstrom store",
    word: "THEAVE",
    characterCount: 6,
  },
  {
    date: "2025-01-03",
    theme: "This local newspaper has been publishing since 1891",
    word: "TIMES",
    characterCount: 5,
  },
  {
    date: "2025-01-04",
    theme: "This Ballard street leads to the Ballard Bridge",
    word: "DRAVUS",
    characterCount: 6,
  },
  {
    date: "2025-01-05",
    theme: "This gold rush trail connected Seattle to Alaska",
    word: "YUKON",
    characterCount: 5,
  },
  {
    date: "2025-01-06",
    theme: "This park in Ballard features a popular off-leash dog area",
    word: "GOLDEN",
    characterCount: 6,
  },
  {
    date: "2025-01-07",
    theme:
      "This botanical attraction near the Space Needle features numerous glass sculptures",
    word: "GARDEN",
    characterCount: 6,
  },
  {
    date: "2025-01-08",
    theme: "UW's athletic teams are known by this mascot",
    word: "HUSKY",
    characterCount: 5,
  },
  {
    date: "2025-01-09",
    theme: "This minimalist coffee shop chain started in Capitol Hill",
    word: "VOAST",
    characterCount: 5,
  },
  {
    date: "2025-01-10",
    theme: "Seattle is known as a hub for this type of beer production",
    word: "CRAFT",
    characterCount: 5,
  },
  {
    date: "2025-01-11",
    theme:
      "This historic burger joint in Fremont is known for its walk-up window",
    word: "UNEEDA",
    characterCount: 6,
  },
  {
    date: "2025-01-12",
    theme:
      "This street in Capitol Hill is lined with popular bars and restaurants",
    word: "PEARL",
    characterCount: 5,
  },
  {
    date: "2025-01-13",
    theme: "This distillery in SODO is known for its locally-made spirits",
    word: "BRANDY",
    characterCount: 6,
  },
  {
    date: "2025-01-14",
    theme:
      "These flowers bloom throughout the city in summer, especially at the Woodland Park Zoo",
    word: "DAHLIA",
    characterCount: 6,
  },
  {
    date: "2025-01-15",
    theme: "These NFL birds play their home games at Lumen Field",
    word: "HAWKS",
    characterCount: 5,
  },

  // New Verified Entries (Starting January 16, 2025)
  {
    date: "2025-01-16",
    theme: "This iconic Seattle ice cream shop is known for its seasonal flavors and long lines",
    word: "MOLLY",
    characterCount: 5,
  },
  {
    date: "2025-01-17",
    theme: "This drive-in burger institution has been serving Seattle since 1954",
    word: "DICKS",
    characterCount: 5,
  },
  {
    date: "2025-01-18",
    theme: "This Pioneer Square tower was once the tallest building west of the Mississippi",
    word: "SMITH",
    characterCount: 5,
  },
  {
    date: "2025-01-19",
    theme: "This seafood company runs oyster bars throughout the city",
    word: "TAYLOR",
    characterCount: 6,
  },
  {
    date: "2025-01-20",
    theme: "This Capitol Hill restaurant served upscale Spanish cuisine and dancing",
    word: "TANGO",
    characterCount: 5,
  },
  {
    date: "2025-01-21",
    theme: "This seafood restaurant chain started as a waterfront fish bar in 1938",
    word: "IVARS",
    characterCount: 5,
  },
  {
    date: "2025-01-22",
    theme: "This Capitol Hill coffee shop features vinyl records and local art",
    word: "ANALOG",
    characterCount: 6,
  },
  {
    date: "2025-01-23",
    theme: "This bay shares its name with a historic downtown bookstore",
    word: "ELLIOT",
    characterCount: 6,
  },
  {
    date: "2025-01-24",
    theme: "This Greenlake salon is known for its talented colorists like @hollybaylisshair",
    word: "FRINGE",
    characterCount: 6,
  },
  {
    date: "2025-01-25",
    theme: "This International District restaurant specializes in hand-pulled noodles",
    word: "BIANG",
    characterCount: 5,
  },
  {
    date: "2025-01-26",
    theme: "This Capitol Hill music venue has hosted countless iconic Seattle shows",
    word: "NEUMOS",
    characterCount: 6,
  },
  {
    date: "2025-01-27",
    theme: "This street hosts one of Capitol Hill's most popular Halloween celebrations",
    word: "ALOHA",
    characterCount: 5,
  },
  {
    date: "2025-01-28",
    theme: "This Queen Anne fine dining institution offers spectacular city views",
    word: "CANLIS",
    characterCount: 6,
  },
  {
    date: "2025-01-29",
    theme: "This Alki Beach institution is famous for their fish and chips",
    word: "SPUDS",
    characterCount: 5,
  },
  {
    date: "2025-01-30",
    theme: "This Capitol Hill spot serves Malaysian street food",
    word: "KEDAI",
    characterCount: 5,
  },
  {
    date: "2025-01-31",
    theme: "This Caribbean sandwich shop started in Fremont",
    word: "PASEO",
    characterCount: 5,
  },
  {
    date: "2025-02-01",
    theme: "This First Hill Italian restaurant has served Seattle since 1953",
    word: "VITOS",
    characterCount: 5,
  },
  {
    date: "2025-02-02",
    theme: "This Capitol Hill bakery specializes in Japanese bread and pastries",
    word: "RONDO",
    characterCount: 5,
  },
  {
    date: "2025-02-03",
    theme: "This Capitol Hill Italian restaurant and bar is known for small plates",
    word: "ARTUSI",
    characterCount: 6,
  },
  {
    date: "2025-02-04",
    theme: "This Pike Street bar features Spanish tapas and multiple levels",
    word: "BARCA",
    characterCount: 5,
  },
  {
    date: "2025-02-05",
    theme: "This Capitol Hill Mexican restaurant is known for their happy hour",
    word: "FOGON",
    characterCount: 5,
  },
  {
    date: "2025-02-06",
    theme: "This International District grocery store makes fresh bento boxes",
    word: "MARUTA",
    characterCount: 6,
  },
  {
    date: "2025-02-07",
    theme: "This South Lake Union spot serves Indian fusion cuisine",
    word: "RUPEE",
    characterCount: 5,
  },
  {
    date: "2025-02-08",
    theme: "This Ballard restaurant is known for inventive Northwest cuisine",
    word: "SAWYER",
    characterCount: 6,
  },
  {
    date: "2025-02-09",
    theme: "This Beacon Hill Filipino restaurant celebrates Pacific Northwest ingredients",
    word: "MUSANG",
    characterCount: 6,
  },
  {
    date: "2025-02-10",
    theme: "This Fremont Korean restaurant is known for their rice bowls",
    word: "REVEL",
    characterCount: 5,
  },
  {
    date: "2025-02-11",
    theme: "This beloved basketball team played their last Seattle game in 2008",
    word: "SONICS",
    characterCount: 6,
  },
  {
    date: "2025-02-12",
    theme: "This tech company's waterfront building features public art",
    word: "ADOBE",
    characterCount: 5,
  },
  {
    date: "2025-02-13",
    theme: "This company built Seattle's iconic transportation system for the World's Fair",
    word: "ALWEG",
    characterCount: 5,
  },
  {
    date: "2025-02-14",
    theme: "This pioneer's name graces a historic Seattle thoroughfare",
    word: "YESLER",
    characterCount: 6,
  },
  {
    date: "2025-02-15",
    theme: "This historic vessel on Lake Union served seafood for decades",
    word: "LUSTY",
    characterCount: 5,
  },
  {
    date: "2025-02-16",
    theme: "This historic waterfront tavern has been serving since 1953",
    word: "SLOOP",
    characterCount: 5,
  },
  {
    date: "2025-02-17",
    theme: "This historic Japanese-American baseball team played at old Sick's Stadium",
    word: "TIGERS",
    characterCount: 6,
  },
  {
    date: "2025-02-18",
    theme: "This historic Capitol Hill music venue helped launch many local bands",
    word: "REDUX",
    characterCount: 5,
  },
  {
    date: "2025-02-19",
    theme: "This company transformed South Lake Union into a tech hub",
    word: "VULCAN",
    characterCount: 6,
  },
  {
    date: "2025-02-20",
    theme: "This historic Queen Anne building housed a youth organization",
    word: "SCOUT",
    characterCount: 5,
  },
  {
    date: "2025-02-21",
    theme: "This arts organization funded many of Seattle's cultural institutions",
    word: "PONCHO",
    characterCount: 6,
  },
  {
    date: "2025-02-22",
    theme: "These races have been a Seattle summer tradition since 1950",
    word: "HYDRO",
    characterCount: 5,
  },
  {
    date: "2025-02-23",
    theme: "These spooky mansion tours reveal Pioneer Square's haunted history",
    word: "SCAREY",
    characterCount: 6,
  },
  {
    date: "2025-02-24",
    theme: "This historic vessel at South Lake Union preserves maritime history",
    word: "BETHEL",
    characterCount: 6,
  },
];

export const getWordForDate = (date) => {
  if (!date || !SEATTLE_WORDS || !Array.isArray(SEATTLE_WORDS)) {
    return null;
  }

  // Convert to PT/Los Angeles timezone
  const ptDate = new Date(
    date.toLocaleString("en-US", { timeZone: "America/Los_Angeles" })
  );

  // Get current date in PT
  const currentPTDate = new Date(
    new Date().toLocaleString("en-US", { timeZone: "America/Los_Angeles" })
  );

  // Reset time parts for accurate date comparison
  currentPTDate.setHours(0, 0, 0, 0);
  ptDate.setHours(0, 0, 0, 0);

  // If requested date is in the future, return null
  if (ptDate > currentPTDate) {
    return null;
  }

  // Format the PT date as YYYY-MM-DD
  const dateString =
    ptDate.getFullYear() +
    "-" +
    String(ptDate.getMonth() + 1).padStart(2, "0") +
    "-" +
    String(ptDate.getDate()).padStart(2, "0");

  return SEATTLE_WORDS.find((entry) => entry.date === dateString);
};

export const findLatestAvailableDate = () => {
  if (!SEATTLE_WORDS || !Array.isArray(SEATTLE_WORDS)) {
    return null;
  }

  // Get current date in PT
  const currentPTDate = new Date(
    new Date().toLocaleString("en-US", { timeZone: "America/Los_Angeles" })
  );
  currentPTDate.setHours(0, 0, 0, 0);

  // Create dates in PT timezone and filter out future dates
  const sortedEntries = SEATTLE_WORDS.map((entry) => ({
    date: new Date(entry.date + "T00:00:00-08:00"), // Force PT timezone
    original: entry,
  }))
    .filter((entry) => entry.date <= currentPTDate) // Filter out future dates
    .sort((a, b) => b.date - a.date);

  return sortedEntries[0]?.original;
};
