import React, { useEffect, useState } from 'react';
import confetti from 'canvas-confetti';

const WinAnimation = ({ isComplete }) => {
  const [hasPlayed, setHasPlayed] = useState(false);

  useEffect(() => {
    if (isComplete && !hasPlayed) {
      const duration = 2000;
      const animationEnd = Date.now() + duration;

      const randomInRange = (min, max) => Math.random() * (max - min) + min;

      const confettiInstance = () => {
        confetti({
          particleCount: 50,
          spread: 70,
          origin: { y: 0.6 },
          colors: ['#1976d2', '#4CAF50', '#FFC107'],
        });
      };

      (function frame() {
        if (Date.now() < animationEnd) {
          confettiInstance();
          requestAnimationFrame(frame);
        }
      }());

      setHasPlayed(true);
    }
  }, [isComplete]);

  return null;
};

export default WinAnimation;