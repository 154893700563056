import React from 'react';
import { Button, Grid, Box } from '@mui/material';

const GameControls = ({ elements, selectedElement, onSelectElement, onClear }) => {
  return (
    <Box sx={{ mb: 6 }}>
      <Grid container spacing={2} justifyContent="center" sx={{ mb: 2 }}>
        {elements.map((element) => (
          <Grid item key={element}>
            <Button
              variant="outlined"
              onClick={() => onSelectElement(element)}
              sx={{
                minWidth: 0,
                width: { xs: 56, sm: 64 },
                height: { xs: 56, sm: 64 },
                fontSize: { xs: '1.5rem', sm: '1.75rem' },
                bgcolor: selectedElement === element ? '#1976d2' : 'transparent',
                color: selectedElement === element ? 'white' : 'inherit',
                borderColor: selectedElement === element ? '#1976d2' : 'grey.300',
                borderWidth: 2,
                '&:hover': {
                  bgcolor: selectedElement === element ? '#1565c0' : 'rgba(0, 0, 0, 0.04)',
                  borderColor: selectedElement === element ? '#1565c0' : 'grey.400'
                }
              }}
            >
              {element}
            </Button>
          </Grid>
        ))}
      </Grid>
      <Button
        variant="outlined"
        onClick={onClear}
        fullWidth
        sx={{
          height: { xs: 40, sm: 48 },
          borderColor: 'grey.300',
          color: 'grey.700',
          maxWidth: 200,
          mx: 'auto',
          display: 'block',
          '&:hover': {
            bgcolor: 'rgba(0, 0, 0, 0.04)',
            borderColor: 'grey.400'
          }
        }}
      >
        Clear
      </Button>
    </Box>
  );
};

export default GameControls;