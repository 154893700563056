import React, { useState } from "react";
import { Box, Typography, Paper } from "@mui/material";
import GameControls from "./GameControls";
import GameBoard from "./GameBoard";
import GameProgress from "./GameProgress";
import { motion, AnimatePresence } from "framer-motion";
import { ShareModal, ShareButton } from "./ShareModal";

const GRID_SIZE = 5;
const ELEMENTS = ["☁️", "🌧️", "☀️"];

const HARD_CODED_PUZZLE = [
  ["☁️", "", "", "", ""],
  ["", "", "🌧️", "", ""],
  ["", "", "", "", "☀️"],
  ["", "☀️", "", "", ""],
  ["", "", "", "☁️", ""],
];

const BLOCKED_CELLS = [
  [false, true, false, false, false],
  [false, false, false, true, false],
  [false, true, false, false, false],
  [false, false, true, false, false],
  [true, false, false, false, false],
];

const WeatherWiz = () => {
  const [grid, setGrid] = useState(
    HARD_CODED_PUZZLE.map((row) => row.map((cell) => cell))
  );
  const [selectedElement, setSelectedElement] = useState(ELEMENTS[0]);
  const [gameStatus, setGameStatus] = useState({
    isComplete: false,
    message: "",
  });

  const checkVictory = (currentGrid) => {
    const counts = { "☁️": 0, "🌧️": 0, "☀️": 0 };
    const duplicates = Array(GRID_SIZE)
      .fill()
      .map(() => Array(GRID_SIZE).fill(false));
    let hasDuplicates = false;

    // Check rows and columns
    for (let i = 0; i < GRID_SIZE; i++) {
      const rowSymbols = new Map();
      const colSymbols = new Map();

      for (let j = 0; j < GRID_SIZE; j++) {
        if (currentGrid[i][j]) counts[currentGrid[i][j]]++;

        // Check row duplicates
        if (currentGrid[i][j] && !BLOCKED_CELLS[i][j]) {
          if (rowSymbols.has(currentGrid[i][j])) {
            duplicates[i][j] = true;
            duplicates[i][rowSymbols.get(currentGrid[i][j])] = true;
            hasDuplicates = true;
          }
          rowSymbols.set(currentGrid[i][j], j);
        }

        // Check column duplicates
        if (currentGrid[j][i] && !BLOCKED_CELLS[j][i]) {
          if (colSymbols.has(currentGrid[j][i])) {
            duplicates[j][i] = true;
            duplicates[colSymbols.get(currentGrid[j][i])][i] = true;
            hasDuplicates = true;
          }
          colSymbols.set(currentGrid[j][i], j);
        }
      }
    }

    const isComplete = Object.values(counts).every((count) => count === 5);

    if (isComplete && !hasDuplicates) {
      setGameStatus({
        isComplete: true,
        message: "Congratulations! You've solved today's WeatherWiz! 🎉",
      });
    }

    return { isComplete: isComplete && !hasDuplicates, duplicates };
  };

  const handleTileClick = (row, col) => {
    if (BLOCKED_CELLS[row][col] || gameStatus.isComplete) return;

    const newGrid = grid.map((row) => [...row]);
    newGrid[row][col] =
      newGrid[row][col] === selectedElement ? "" : selectedElement;
    setGrid(newGrid);
    checkVictory(newGrid);
  };

  const handleClear = () => {
    setGrid(HARD_CODED_PUZZLE.map((row) => [...row]));
    setGameStatus({ isComplete: false, message: "" });
  };

  const [shareModalOpen, setShareModalOpen] = useState(false);

  const handleShare = () => {
    const shareText = `WeatherWiz\n${grid
      .map((row) => row.map((cell) => cell || "⬜").join(""))
      .join("\n")}`;

    navigator.clipboard.writeText(shareText).then(() => {
      setShareModalOpen(false);
    });
  };

  const { duplicates, isComplete } = React.useMemo(
    () => checkVictory(grid),
    [grid]
  );

  return (
    <Box sx={{ bgcolor: "background.default", minHeight: "100vh", p: 3 }}>
      <Paper elevation={3} sx={{ p: 3, maxWidth: 600, margin: "auto" }}>
        <Typography textAlign={"center"} variant="h4" gutterBottom>
          ☔ Weather Wiz
        </Typography>

        <Typography
          mb={3}
          textAlign={"center"}
          variant="body1"
          color="textSecondary"
          gutterBottom
        >
          Place exactly 5 of each weather symbol. No duplicates in rows or
          columns.
        </Typography>

        <AnimatePresence>
          {gameStatus.message && (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <Typography
                variant="body1"
                sx={{
                  my: 2,
                  color: "success.main",
                  fontWeight: "bold",
                }}
              >
                {gameStatus.message}
              </Typography>
            </motion.div>
          )}
        </AnimatePresence>

        <GameProgress grid={grid} />
        <GameControls
          elements={ELEMENTS}
          selectedElement={selectedElement}
          onSelectElement={setSelectedElement}
          onClear={handleClear}
        />

        <GameBoard
          grid={grid}
          onTileClick={handleTileClick}
          blockedCells={BLOCKED_CELLS}
          hardCodedPuzzle={HARD_CODED_PUZZLE}
          duplicates={duplicates}
          isComplete={gameStatus.isComplete}
        />

        <ShareModal
          open={shareModalOpen}
          onClose={() => setShareModalOpen(false)}
          onShare={handleShare}
          grid={grid}
          isComplete={gameStatus.isComplete}
        />

        <Box textAlign={"center"}>
          <ShareButton onClick={() => setShareModalOpen(true)} />
        </Box>
      </Paper>
    </Box>
  );
};

export default WeatherWiz;
