import React from 'react';
import { Box, Typography, Grid } from '@mui/material';

const GameProgress = ({ grid }) => {
  const counts = {
    '☁️': 0, '🌧️': 0, '☀️': 0
  };

  grid.forEach(row => {
    row.forEach(cell => {
      if (cell) counts[cell]++;
    });
  });

  return (
    <Grid container spacing={2} justifyContent="center" sx={{ mb: 2 }}>
      {Object.entries(counts).map(([symbol, count]) => (
        <Grid item key={symbol}>
          <Box sx={{ 
            p: 1,
            borderRadius: 1,
            bgcolor: 'grey.100',
            minWidth: 80,
            textAlign: 'center'
          }}>
            <Typography fontSize="1.2rem">{symbol}</Typography>
            <Typography variant="body2" color="text.secondary">
              {count}/5
            </Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default GameProgress;