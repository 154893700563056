import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let theme = createTheme({
  palette: {
    primary: {
      main: '#cca59f',
    },
    secondary: {
      main: '#ebe2d1',
    },
    background: {
      default: '#f4f0df',
      paper: '#FBFAF5',
    },
    text: {
      primary: '#000000',
      secondary: '#000000',
    },
  },
  typography: {
    fontFamily: "'Open Sans', sans-serif",
    h1: {
      fontFamily: "'Urbanist', sans-serif",
      fontWeight: 500,
      fontSize: '3rem',
      lineHeight: 1.2,
    },
    h2: {
      fontFamily: "'Urbanist', sans-serif",
      fontWeight: 500,
      fontSize: '2.5rem',
      lineHeight: 1.3,
    },
    h3: {
      fontFamily: "'Urbanist', sans-serif",
      fontWeight: 500,
      fontSize: '2rem',
      lineHeight: 1.4,
    },
    h4: {
      fontFamily: "'Urbanist', sans-serif",
      fontWeight: 500,
      fontSize: '1.75rem',
    },
    h5: {
      fontFamily: "'Urbanist', sans-serif",
      fontWeight: 500,
      fontSize: '1.5rem',
    },
    h6: {
      fontFamily: "'Urbanist', sans-serif",
      fontWeight: 500,
      fontSize: '1.25rem',
    },
    body1: {
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: 400,
      fontSize: '1rem',
    },
    body2: {
      fontFamily: "'Open Sans', sans-serif",
      fontWeight: 400,
      fontSize: '0.875rem',
    },
    button: {
      fontFamily: "'Urbanist', sans-serif",
      fontWeight: 600,
      textTransform: 'none',
      fontSize: '1rem',
    },
    caption: {
      fontFamily: "'Open Sans', sans-serif",
      fontSize: '0.75rem',
      fontWeight: 400,
    },
    overline: {
      fontFamily: "'Open Sans', sans-serif",
      fontSize: '0.75rem',
      fontWeight: 600,
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#000000', // Default text color
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none', // No uppercase transformation
          fontWeight: 600, // Bold text for buttons
        },
      },
    },
  },
});

theme = responsiveFontSizes(theme); // Add responsive font sizes automatically

export default theme;
