import React from 'react';
import { Grid, Box } from '@mui/material';
import GameTile from './GridTile';

import WinAnimation from './WinAnimation';

const GameBoard = ({ grid, onTileClick, blockedCells, hardCodedPuzzle, duplicates, isComplete }) => {
  return (
    <Box sx={{
      width: '100%',
      maxWidth: {
        xs: '350px',
        sm: '500px'
      },
      aspectRatio: '1',
      margin: '20px auto',
    }}>
      <Grid
        container
        sx={{
          width: '100%',
          height: '100%',
          '& .MuiGrid-item': {
            padding: { xs: '2px', sm: '4px' },
            height: `${100 / grid.length}%`,
          }
        }}
      >
        {grid.map((row, rowIndex) =>
          row.map((value, colIndex) => (
            <Grid
              item
              xs={12 / grid.length}
              key={`${rowIndex}-${colIndex}`}
            >
              <GameTile
                row={rowIndex}
                col={colIndex}
                value={value}
                onClick={() => onTileClick(rowIndex, colIndex)}
                isBlocked={blockedCells[rowIndex][colIndex]}
                isPermanent={hardCodedPuzzle[rowIndex][colIndex] !== ''}
                isDuplicate={duplicates[rowIndex][colIndex]}
              />
            </Grid>
          ))
        )}
      </Grid>
      <WinAnimation isComplete={isComplete} grid={grid} />
    </Box>
  );
};

export default GameBoard;